<template>
  <div class="fill-default login--container">
    <!-- Header -->
    <div class="header py-lg-5 log--header">
      <div class="separator separator-bottom separator-skew zindex-100 fill-default">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="sign--container">
      <div class="sign-left">
        <div class="form-container">
          <h1 class="text-white">
            Complete Test Coverage Now
          </h1>
          <!-- <form role="form" @submit.prevent="handleSubmit()">
            <div class="row">
              <div class="col-md-12 mb-3">
                <el-input class="input-event" placeholder="Enter event code" name="EventCode"
                  prefix-icon="fa fa-solid fa-hashtag" suffix-icon="el-icon-right" v-model="eventCode">
                </el-input>
              </div>
            </div>
          </form> -->
        </div>
      </div>
      <div class="sign--content py-3">
        <div class="text-center" >
          <router-link to="/">
            <img src="/img/brand/wring.png" width="120" />
          </router-link>
          <p class="mb-4 mt--2 nb--title">Wring</p>
          <!-- <h1 class="text-white">Create an account</h1>
          <p class="text-lead text-white">
            Sign up for the free community tier
          </p> -->
        </div>
        <div class="sign--form" v-loading="loading">
          <div class="bg-secondary s--card">
            <div class="card-header bg-transparent pb-3">
              <div class="text-muted text-center mt-1 mb-3">
                <small>Sign up with</small>
              </div>
              <div class="text-center">
                <a :href="githubUrl" class="btn btn-neutral btn-icon mr-4">
                  <span class="btn-inner--icon"><img src="/img/icons/common/github.svg" /></span>
                  <span class="btn-inner--text">Github</span>
                </a>
                <a :href="googleUrl" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--icon"><img src="/img/icons/common/google.svg" /></span>
                  <span class="btn-inner--text">Google</span>
                </a>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-3">
              <div class="text-center text-muted mb-4">
                <small>Or sign up with credentials</small>
              </div>
              <form role="form" @submit.prevent="handleSubmit()">
                <div class="row">
                  <div class="col-md-12 mb-0">
                    <base-input alternative class="mb-1" prepend-icon="ni ni-hat-3" placeholder="Name" name="Name"
                      v-model="name">
                    </base-input>
                    <validation-error :errors="apiValidationErrors.name" />
                  </div>
                  <div class="col-md-12 mb-0">
                    <base-input alternative class="mb-1" prepend-icon="ni ni-email-83" placeholder="Email" name="Email"
                      v-model="email">
                    </base-input>
                    <validation-error :errors="apiValidationErrors.email" />
                  </div>
                  <div class="col-md-12 mb-0">
                    <base-input alternative class="mb-1" prepend-icon="ni ni-lock-circle-open" placeholder="Password"
                      type="password" name="Password" v-model="password">
                    </base-input>
                    <password v-model="password" :strength-meter-only="true" @score="showScore"
                      :showStrengthMeter="false" />

                    <validation-error :errors="apiValidationErrors.password" />
                  </div>
                  <div class="col-md-12 mb-0">
                    <base-input alternative prepend-icon="ni ni-lock-circle-open" placeholder="Confirm Password"
                      type="password" name="Password confirmation" v-model="password_confirmation">
                    </base-input>
                    <validation-error :errors="apiValidationErrors.password_confirmation" />
                  </div>
                </div>

                <div class="text-muted font-italic">
                  <small>password strength:
                    <template v-if="scors === 0">
                      <span class="text-danger font-weight-700">
                        very weak
                      </span>
                    </template>

                    <template v-if="scors === 1">
                      <span class="text-danger font-weight-700"> weak </span>
                    </template>

                    <template v-if="scors === 2">
                      <span class="text-warning font-weight-700"> medium </span>
                    </template>

                    <template v-if="scors === 3">
                      <span class="text-success font-weight-700"> strong </span>
                    </template>

                    <template v-if="scors === 4">
                      <span class="text-success font-weight-700">
                        very strong
                      </span>
                    </template>
                  </small>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <base-input :rules="{
                        required: { allowFalse: false },
                      }" name="Privacy" Policy>
                      <base-checkbox v-model="boolean">
                        <span class="text-muted">I agree with the
                          <a href="/service-agreement" target="_blank">Terms and conditions</a></span>
                      </base-checkbox>
                    </base-input>
                  </div>
                </div>
                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="my-3">Create account</base-button>
                </div>
                <div class="text-center text-muted my-1">
                  <small>Have an account? <a href="/login">Log in</a> </small>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";

export default {
  components: {
    ValidationError,
    Password,
  },
  mixins: [formMixin],
  data() {
    return {
      googleUrl:
        process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/oauth2/custom/" + "google",
      githubUrl:
        process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/oauth2/custom/" + "github",
      name: null,
      boolean: false,
      email: null,
      password: null,
      password_confirmation: null,
      scors: "",
      eventCode:"",
      loading: false,
    };
  },

  methods: {
    showScore(score) {
      this.scors = score;
    },
    async handleSubmit() {
      if (!this.boolean) {
        await this.$notify({
          type: "danger",
          message: "You need to agree with our terms and conditions.",
        });
        return;
      }
      const user = {
        fullName: this.name,
        email: this.email,
        password: this.password,
      };
      const requestOptions = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      if (!this.name) {
        this.$notify({
          type: "danger",
          message: "Please enter a name",
        });
      } else if (!this.email) {
        this.$notify({
          type: "danger",
          message: "Please enter an email",
        });
      } else if (user.password.length < 8) {
        this.$notify({
          type: "danger",
          message: "Please use a password containing at least 8 characters",
        });
      } else if (user.password != this.password_confirmation) {
        this.$notify({
          type: "danger",
          message: "Password and password confirmation are not identical",
        });
      } else {
        try {
          this.loading = true;
          await this.$store
            .dispatch("register", { user, requestOptions })
            .then((res) => {
              if (res === "code 400") {
                this.$notify({
                  type: "danger",
                  message:
                    "Your password is on the list of the most common passwords and is vulnerable to guessing.Your password cannot be all numbers.",
                });
              } else {
                this.$notify({
                  type: "success",
                  message: "Successfully registered.",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          this.$notify({
            type: "danger",
            message: error.response.data.message,
          });
          this.setApiValidation(error.response.data.errors);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.login--container {
  height: 100vh;
  overflow: hidden;
}

.nb--title {
  font-size: .9rem;
  color: #1a237e;
  text-transform: uppercase;
  font-weight: 700;
}

.log--header {
  min-height: 50vh;
}

.sign--container {
  position: absolute;
  top: 0;
  display: flex;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .sign--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 60vw;
    height: 125vh;

    .sign--form {
      width: clamp(450px, 40vw, 700px);
      height: 100%;

      .s--card {
        border-radius: 0.5rem !important;
      }
    }
  }
}
.primarycolor {
  color: #150FD1;
}
.sign-left {
  width: 40vw;
  height: 100vh;
  background-color: #5E72E4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-event {
  height: 40px !important;
  border-radius: 24px !important;
}
.input-even > .el-input__suffix {
  border-radius: 24px;
    width: 40px;
    background-color: #5e98e4;
}
</style>
