<template>
  <div class="fill-default login--container">
    <!-- Header -->
    <div class="header bg-gradient-danger py-lg-5 log--header">
      <div
        class="separator separator-bottom separator-skew zindex-100 fill-default"
      >
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="sign--container">
      <div class="sign--content py-3">
        <div class="text-center">
          <router-link to="/">
            <img src="/img/brand/wring.png" width="120" />
          </router-link>
          <p class="mt--2 nb--title" style="color:white">Wring</p>
          <h1 class="text-white">
            Accelerate your Innovation
          </h1>
        </div>
        <div class="mt-3 sign--form" v-loading="loading">
          <div class="bg-secondary s--card text-center">
            <h1>
              Congratulation!
              <i class="fa fa-check-circle" aria-hidden="true"></i>
            </h1>
            <br />
            <p>
              We've sent an email to verify your account sign up. Please click on
              the link in that email to verify your account
            </p>
            <div class="text-center">
              <a
                href="/login"
                class="btn btn-primary my-2"
              >
                Log In
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";

export default {
  mixins: [formMixin],
  data() {
    return {};
  },

  methods: {},
};
</script>
<style scoped lang="scss">
.login--container {
  height: 100vh;
  overflow: hidden;
}

.log--header {
  min-height: 50vh;
}
.sign--container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5E72E4;

  .sign--content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .sign--form {
      width: clamp(450px, 40vw, 700px);
      .s--card {
        border-radius: 0.5rem !important;
        padding: 5rem 3rem;
      }
    }
  }

  .sign--form i {
    color: green !important;
  }
}
</style>
